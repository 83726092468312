import React, {PureComponent} from 'react';

import {Helmet} from 'react-helmet';


function renderMath(element) {
    const render = window.renderMathInElement;

    element = element || document.getElementById('page-container');

    if (render) {
        render(element, {macros: {
            '\\dd': '{\\mathrm{d} #1}',
            '\\defeq': ':=',
            '\\abs': '\\left|#1\\right|',
            '\\abss': '|#1|',
        }});
    } else {
        setTimeout(() => renderMath(element), 500);
    }
}

class Page extends PureComponent {
    componentDidMount() {
        // Initialize page root and scroll handler
        this.root = document.getElementById('page-container');
        this.scrollHandler = () => this.handleScroll();

        // Add scroll handler and trigger once
        this.root.addEventListener('scroll', this.scrollHandler);
        this.scrollHandler();

        // Render math with KaTeX
        renderMath();
    }

    componentWillUnmount() {
        // Remove scroll handler
        this.root.removeEventListener('scroll', this.scrollHandler);
    }

    handleScroll() {
        // Prevent horizontal scroll
        if (this.root.scrollLeft > 0) {this.root.scrollLeft = 0;}

        // Record scroll data for css
        const position = Math.max(this.root.scrollTop, 0);
        document.documentElement.dataset.scroll = position;
    }
    render() {
        const {title, children} = this.props;
        return <div id='page-container'>
            <Helmet>
                <title>Samuel J. Li — {title}</title>
            </Helmet>
            {children}
        </div>;
    }
}

export {renderMath};
export default Page;

import {createMuiTheme} from '@material-ui/core/styles';
import {blue} from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: blue
  },
});

export default theme;

const projectData = {
  quadratic_sieve: {
    title: 'Quadratic Sieve',
    description: 'An interactive visualization of the quadratic sieve algorithm for integer factorization. Made with WebGL.',
    date: 'January 2023',
    image: 'quadratic-sieve.png',
    url: '/quadratic-sieve',
    tags: ['webgl', 'math', 'cryptography', 'react'],
    social: {
        'github': 'https://github.com/wgxli/quadratic-sieve',
    }
  },
  coherent_states: {
    title: 'Atomic Coherent States',
    description: 'An interactive visualization of the stretched representation for many coupled two-level systems. Made with WebGL.',
    date: 'July 2021',
    image: 'coherent-states.png',
    url: '/atomic-coherent-states',
    tags: ['webgl', 'simulation', 'physics'],
    social: {
        'github': 'https://github.com/wgxli/atomic-coherent-states',
    }
  },
  simplefhe: {
    title: 'SimpleFHE Python Library',
    description: 'A dead-simple Python library for full homomorphic encryption (FHE). Allows computation on encrypted client data without any server-side decryption. Available on the Python 3 package index.',
    date: 'July 2020',
    image: 'simplefhe.png',
    url: 'https://github.com/wgxli/simple-fhe',
    tags: ['python', 'library', 'cryptography'],
    social: {
            'github': 'https://github.com/wgxli/simple-fhe'
    }
  },

  ising_spin_model: {
    title: 'Ising Spin Model',
    description: 'A blazing-fast interactive implementation of the Ising square-lattice spin model. Made with WebGL.',
    date: 'July 2020',
    image: 'ising-spin-model-compressed.png',
    url: '/ising-spin-model',
    tags: ['physics', 'react', 'webgl', 'simulation'],
    social: {
            'github': 'https://github.com/wgxli/ising-spin-model'
    }
  },

  elliptic_curve_explorer: {
    title: 'Elliptic Curve Explorer',
    description: 'An interactive tool to explore rational elliptic curves in Weierstrass form. Visualization in both 2D (affine) and 3D (projective) space. Made with React and three.js.',
    date: 'April 2019',
    image: 'elliptic-curve-explorer-compressed.jpg',
    url: '/elliptic-curve-explorer',
    tags: ['math', 'react', 'webgl', 'three.js'],
    social: {
            'github': 'https://github.com/wgxli/elliptic-curve-explorer'
    }
  },

  hopf_fibration: {
    title: 'Hopf Fibration Visualization',
    description: 'An interactive visualization of the Hopf fibration, stereographically projected into 3-space. Made with three.js.',
    date: 'March 2019',
    image: 'hopf-fibration-compressed.jpg',
    url: '/hopf-fibration',
    tags: ['math', 'webgl', 'three.js'],
    social: {
            'reddit': 'https://www.reddit.com/r/math/comments/b4xcxe/i_made_an_interactive_visualization_of_the_hopf/',
            'github': 'https://github.com/wgxli/hopf-fibration'
    }
  },

  complex_function_plotter: {
    title: 'Complex Function Plotter',
    description: 'An interactive domain coloring plotter for visualizing complex functions. Highly responsive visualization of functions parameterized by an arbitrary number of variables. Made with React and WebGL.',
    date: 'July 2018',
    image: 'complex-function-plotter-compressed.jpg',
    url: '/complex-function-plotter',
    tags: ['math', 'react', 'webgl'],
    social: {
            'reddit': 'https://www.reddit.com/r/math/comments/dofwty/zooming_in_on_the_natural_boundary_of_z2n/',
            'github': 'https://github.com/wgxli/complex-function-plotter'
    }
  },

  quantum_simulator: {
    title: 'Quantum Simulator',
    description: 'An interactive simulation of the one-dimensional time-dependent Schrödinger equation. Features stable time acceleration, views in different eigenbases, and simulated measurement. Made with Python 3.',
    date: 'July 2018',
    image: 'quantum-simulator-compressed.png',
    url: 'https://github.com/wgxli/quantum-simulator',
    tags: ['python', 'simulation', 'physics'],
	social: {
	  'github': 'https://github.com/wgxli/quantum-simulator'
	}
  },

  latex_scratchpad: {
    title: 'LaTeX Scratchpad',
    description: 'Minimalist, highly responsive equation editor for quick-and-dirty scratch work. Made with the KaTeX library.',
    date: 'December 2017',
    image: 'latex-scratchpad.png',
    url: '/legacy-website/pages/latex-scratchpad/scratchpad.html',
    tags: ['latex', 'math']
  },

  hs_teaching_aids: {
    title: 'High-School Teaching Aids',
    description: 'Past teaching aids for an IB Mathematics HL class of about twenty students. Includes interactive applets and challenging exercises.',
    date: 'August 2017',
    image: 'hs-teaching-aid.png',
    url: '/legacy-website/index.html',
    tags: ['teaching', 'math']
  },

  piano_transcription: {
    title: 'DNN-Based Piano Transcription',
    description: 'Uses deep neural networks to extract note onset times from piano recordings. I was selected to present this research at the 2017 Joint Mathematics Meetings. Made with TensorFlow.',
    date: 'July 2017',
    image: 'piano-transcription-compressed.png',
    url: 'https://github.com/wgxli/piano-transcription',
    tags: ['machine-learning', 'music', 'tensorflow', 'python'],
    social: {
            'github': 'https://github.com/wgxli/piano-transcription'
    }
  },

  simpletex: {
    title: 'SimpleTeX Python Library',
    description: 'A simple, hassle-free library for creating LaTeX documents in Python. Features automatic import management, easy global styling, and minimalist syntax. Available on the Python 3 package index.',
    date: 'July 2016',
    image: 'simpletex-compressed.png',
    url: 'https://pypi.org/project/simpletex',
    tags: ['python', 'latex', 'library'],
    social: {
            'github': 'https://github.com/wgxli/simpletex'
    }
  },

  toy_raytracer: {
    title: 'Toy Raytracer',
    description: 'A simple raytracer I wrote in C++. Features depth of field, adaptive sampling, and correct treatment of reflective, refractive, and diffuse surfaces (including Fresnel).',
    date: 'August 2015',
    image: 'raytracer-compressed.jpg',
    url: 'https://github.com/wgxli/toy-raytracer',
    tags: ['C++', '3D', 'graphics'],
    social: {
      'github': 'https://github.com/wgxli/toy-raytracer'
    }
  },
}

export default projectData;

import React from 'react';

import {Helmet} from 'react-helmet';
import {Switch, Route} from 'react-router-dom';

import ImageBanner from 'components/ImageBanner/ImageBanner.js';

import Page from 'pages/Page.js';
import BlogPost from './BlogPost';

import 'pages/pages.css';
import './blog-container.css';

import FlipMove from 'react-flip-move';
import BlogEntry from './Card';
import TagFilter from 'components/TagFilter';

import metadata from './posts/database.json';


class BlogPage extends React.PureComponent {
    state = {tags: []};

    renderProjects() {
        const output = [];
        for (let entry of metadata) {
            if (this.shouldDisplayPost(entry)) {
                const id = entry.id;
                const intro = import(`./posts/${id}/intro.js`);
                output.push(<BlogEntry
                    key={id}
                    metadata={entry}
                    promise={intro}
                    destination={`/blog/${id}#page-top`}
                    handleTagClick={this.handleTagClick.bind(this)}
                />);
            }
        }
        return output;
    }

    shouldDisplayPost(entry) {
        const {tags} = this.state;
        return tags.every(x => entry.tags.includes(x));
    }

    addTag(tag) {
        this.setState(state => {
            const tags = [...state.tags];
            if (tags.indexOf(tag) < 0) {
                tags.push(tag);
            }
            return {tags};
        });

    }

    deleteTag(target_tag) {
        this.setState(state => ({
            tags: state.tags.filter(tag => tag !== target_tag)
        }));
    }

    handleTagClick(event, tag) {
        event.stopPropagation();
        this.addTag(tag);
    }


    render() {
        const {tags} = this.state;

        return <Page title='Blog'>
            <Helmet>
                <title>Samuel J. Li — Blog</title>
            </Helmet>
            <Switch>
                <Route path='/blog/:postId' component={BlogPost}/>
                <Route path='/blog'>
                    <ImageBanner image='blog'>
                        <h1>Blog</h1>
                        <p>My ramblings, some of which are coherent.</p>
                    </ImageBanner>
                    <div id='content-container'>
                        <TagFilter
                            tags={tags}
                            deleteTag={this.deleteTag.bind(this)}
                        />
                        <FlipMove id='blog-container'>
                            {this.renderProjects()}
                        </FlipMove>
                    </div>
                 </Route>
            </Switch>
        </Page>;
    }
}

export default BlogPage;

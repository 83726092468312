import React, {createRef} from 'react';
import {Link} from 'react-router-dom';

import {renderMath} from 'pages/Page.js';

import './card.css';


const MONTH_LIST = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
];


class BlogEntry extends React.PureComponent {
    state = {};

    renderHeader() {
        const {metadata} = this.props;
        const [year, month, day] = metadata.date;

        const dateString = `${MONTH_LIST[month-1]} ${day}, ${year}`;

        return <div className='blog-header'>
                <p className='blog-subtitle'>
                    <span className='date'>{dateString}</span>
                    •
                    <span className='read-time'>{metadata.readTime} minute read</span>
                </p>
                <h2 className='blog-title'>{metadata.title}</h2>
        </div>;
    }

    renderTag(tag) {
        return <div className='blog-tag'
            key={tag}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
                this.props.handleTagClick(e, tag);
                return false;
            }}
        >{tag}</div>;
    }

    componentDidUpdate() {
        setTimeout(() => renderMath(this.ref.current), 0);
    }


    render() {
        const {metadata, promise, destination} = this.props;
        this.ref = createRef();

        if (this.state.intro === undefined) {
            promise.then(
                data => this.setState({intro: data.default})
            ).catch(
                error => console.error(`Blog post ${metadata.id} failed to load:`, error)
            );
            return <div className='blog-card loading'>Loading Entry...</div>;
        }
        const {intro} = this.state;
        return (
            <Link to={destination} className='blog-card' ref={this.ref}>
                {this.renderHeader()}
                <div className='blog-tags'>
                    {metadata.tags.map(this.renderTag.bind(this))}
                </div>
                <div className='blog-info'>
                    {intro()}
                    <div className='blog-info-cover'/>
                </div>
            </Link>
        );
    }
}

export default BlogEntry;

import React from 'react';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import './card.css';


class ProjectCard extends React.PureComponent {
  renderImage() {
    const {data} = this.props;
    return (
      <a href={data.url}>
            <CardMedia
                className='project-image'
                image={'/images/projects/' + data.image}
                role='img'
                aria-label={data.title.toLowerCase() + ' image'}
            />
      </a>
    );
  }

  renderHeader() {
      const {data} = this.props;
    return (
      <div className='project-header'>
	<h2 className='project-title'>{data.title}</h2>
        <div className='project-date'>
                <Typography
                  variant='caption'
                  noWrap
                >
                  {data.date}
                </Typography>
        </div>
      </div>
    );
  }

  renderTag(tag) {
      return <Chip
	key={tag}
	label={tag}
	className='project-tag'
	onClick={e => this.props.handleTagClick(e, tag)}
      />
  }

  renderSocial() {
      const {data} = this.props;
      const icons = [];
      if ('social' in data) {
          for (var site in data.social) {
              const link = data.social[site];
              const image = '/icons/social/' + site + '.svg';
              icons.push(
                      <a href={link} key={site}>
                            <img src={image} alt={site + ' social icon'}/>
                      </a>
              );
          }
      }
      return icons;
  }

  render() {
      const {data} = this.props;
    return (
      <Card
	className='project-card'
      >
	{this.renderImage()}
	<CardContent className='project-info-container'>
	  <a href={data.url} className='project-info'>
		{this.renderHeader()}
		<p>{data.description}</p>
	  </a>

	  <div className='project-footer'>
		  <div className='project-tags-container'>
			<Typography
		      className='project-tag-label'
			  variant='caption'
			>Tags:</Typography>
			<div className='project-tags'>
			    {data.tags.map(this.renderTag.bind(this))}
			</div>
		  </div>

		  <div className='project-social'>
			{this.renderSocial()}
		  </div>
	  </div>
	</CardContent>
      </Card>
    );
  }
}

export default ProjectCard;

import 'babel-polyfill';

import React from 'react';
import {hydrate} from 'react-dom';
import {render} from 'react-snapshot';

import './index.css';
import App from './App';


const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App/>, rootElement);
} else {
  render(<App/>, rootElement);
}

/* Do not register service worker due to cache issues */
//import registerServiceWorker from './registerServiceWorker';
//registerServiceWorker();

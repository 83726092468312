import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';

import BaseQRCode from 'qrcode.react';


class QRCode extends PureComponent {
    state = {url: ''};

    componentDidMount() {
        const {history} = this.props;
        this.unlisten = history.listen(this.update.bind(this));
        this.update();
    }

    update() {
        this.setState({url: window.location.href});
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const {size} = this.props;
        const {url} = this.state;
        return <div className='container'>
            <BaseQRCode value={url} size={size}/>
            <style jsx>{`
            .container {
                transform-origin: top right;
                transform: scale(0.25);
                position: absolute;
                top: 0;
                right: 0;

                z-index: 1000;

                display: none;
            }

            @media print {
                .container {
                    display: block;
                }
            }
        `}</style>
        </div>;
    }
}

export default withRouter(QRCode);

const socialButtons = {
  'github': {
    icon: require('./icons/github.svg'),
    url: 'https://github.com/wgxli'
  },
  'linkedin': {
    icon: require('./icons/linkedin.svg'),
    url: 'https://www.linkedin.com/in/samuelj-li'
  }
}

export default socialButtons;

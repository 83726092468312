import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import NavigationBar from './components/NavigationBar';

import Projects from './pages/Projects';
import Blog from './pages/Blog';
import About from './pages/About';


const routes = [
    {
        name: 'Projects',
        path: '/projects/',
        component: Projects
    },
    {
        name: 'Blog',
        path: '/blog/',
        component: Blog
    },
    {
        name: 'About',
        path: '/about/',
        component: About
    },
];


class App extends React.PureComponent {
  getTabs() {
      return Object.fromEntries(routes.map(
        ({name, path}) => [name, path]
      ));
  }

  renderRoutes() {
      return routes.map(
          ({path, component}) =>
          <Route key={path} path={path} component={component}/>
      );
  }

  render() {
    return <Router>
      <MuiThemeProvider theme={theme}>
      <NavigationBar tabs={this.getTabs()}/>
        <Switch>
          {this.renderRoutes()}
          <Route path='/'><Redirect to='/projects/'/></Route>
        </Switch>
      </MuiThemeProvider>
    </Router>;
  }
}

export default App;

import React from 'react';

import {Helmet} from 'react-helmet';

import FlipMove from 'react-flip-move';

import ImageBanner from 'components/ImageBanner/ImageBanner.js';

import Page from 'pages/Page.js';

import 'pages/pages.css';
import './project-page.css';

import ProjectCard from './Card';
import TagFilter from 'components/TagFilter';
import projectData from './database';


class ProjectPage extends React.PureComponent {
  state = {tags: []};

  renderProjects(tags) {
    return Object.entries(projectData).filter(
      ([key, data]) => {
	const project_tags = new Set(data.tags);
	return tags.every(tag => project_tags.has(tag));
      }
    ).map(
      ([key, data]) => {
	return (
	  <ProjectCard
	    key={key}
	    data={data}
	    handleTagClick={this.handleTagClick.bind(this)}
	  />
	);
      }
    );
  }

  addTag(tag) {
    this.setState(state => {
      const tags = [...state.tags];
      if (tags.indexOf(tag) < 0) {
	tags.push(tag);
      }
      return {tags};
    });

  }

  deleteTag(target_tag) {
    this.setState(state => ({
      tags: state.tags.filter(tag => tag !== target_tag)
    }));
  }

  handleTagClick(event, tag) {
    event.stopPropagation();
    this.addTag(tag);
  }
 

  render() {
    const tags = this.state.tags;
    return <Page title='Projects'>
        <Helmet>
            <title>Samuel J. Li — Projects</title>
        </Helmet>
	<ImageBanner image='projects'>
	  <h1>Projects</h1>
	  <p>A library of the projects I’ve compiled over the years.</p>
	</ImageBanner>
	<div id='content-container'>
	  <TagFilter
	    tags={tags}
	    deleteTag={this.deleteTag.bind(this)}
	  />
	  <FlipMove id='project-container'>
	    {this.renderProjects(tags)}
	  </FlipMove>
	</div>
    </Page>;
  }
}

export default ProjectPage;

import React from 'react';

import {Flipper, Flipped} from 'react-flip-toolkit';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import './tag-filter.css';


const styles = theme => ({
  root: {
    flexWrap: 'wrap',
  },
  chip: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
});

// Taken from material-ui ChipArray demo
class TagFilter extends React.PureComponent {
  render() {
    const {classes, tags} = this.props;

    const classList = [classes.root, 'tag-filter'];
    if (tags.length === 0) {classList.push('empty');}

    return <Paper
       key='tag-filter'
       className={classList.join(' ')}>
       <p>Filter Tags:</p>
       <div className='tags'>
         <Flipper flipKey={tags.join(' ')}>
           {tags.map(tag =>
             <Flipped key={tag} flipId={tag}>
                 <Chip
                   key={tag}
                   label={tag}
                   onDelete={() => this.props.deleteTag(tag)}
                   className={classes.chip}
                 />
             </Flipped>
           )}
         </Flipper>
       </div>
     </Paper>;
  }
}

TagFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TagFilter);

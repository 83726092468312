import React from 'react';

import {NavLink} from 'react-router-dom';

import './navigation.css';
import socialButtons from './socialButtons';

import QRCode from '../QRCode';


class NavigationBar extends React.PureComponent {
    renderTabs() {
        const {tabs} = this.props;
        return Object.entries(tabs).map(
            ([name, path]) =>
                <NavLink
                    key={path}
                    to={path}
                    className='nav-tab'
                    activeClassName='selected'
                >
                    {name}
                </NavLink>
        )
    }

    renderSocialButtons() {
        const buttons = [];
        for (const [key, data] of Object.entries(socialButtons)) {
            buttons.push(
                <a key={key} href={data.url}>
                    <img
                        className='social-icon'
                        src={data.icon}
                        alt={key + ' social media icon'}
                    />
                </a>
            );
        }
        return buttons;
    }

    render() {
        const logo = (
            <div id='main-logo-container'>
                <NavLink to='/' id='main-logo'>
                    Samuel J. Li
                </NavLink>
            </div>
        );
        const socialButtons = (
            <div id='social-buttons'>
                {this.renderSocialButtons()}
            </div>
        );

        return (
            <header id='navigation-bar'>
                <div id='main-bar'>
                    {logo}
                    <nav className='desktop navigation-tabs'>
                        {this.renderTabs()}
                    </nav>
                    {socialButtons}
                </div>
                <nav className='mobile navigation-tabs'>
                    {this.renderTabs()}
                </nav>
                <QRCode size={300}/>
            </header>
        );
    }
}

export default NavigationBar;

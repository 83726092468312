import React from 'react';

import Page from 'pages/Page.js';

import ImageBanner from '../../components/ImageBanner/ImageBanner.js';

import '../pages.css';
import './about.css';


class AboutPage extends React.PureComponent {
  render() {
    return (
      <Page title='About Me'>
	<ImageBanner image='about'>
	  <h1>About Me</h1>
	  <p>Who I am, where I'm at, and where I'm going.</p>
	</ImageBanner>
	<div className='about' id='content-container'>
	  <div className='text-container'>
              <p>Hi! I’m Samuel Li. I currently work at Samsung Semiconductor, where I help develop the computational models used for nanometer-scale photolithography.</p>

              <p>I completed my undergraduate degree in mathematics, physics, and computer science at the University of Toronto. Throughout my senior year, I ran an <a href="https://arxiv.org/abs/2207.07279">experiment</a> in the <a href="https://www.physics.utoronto.ca/~vutha/">Vutha lab</a> to grow and probe cryogenic neon crystals doped with barium monofluoride (BaF:Ne). The experiment successfully demonstrated the fundamental first steps needed to measure the electron electric dipole moment (eEDM) using molecules trapped in solids. The eEDM places strong constraints on potential physics beyond the Standard Model, such as proposed candidates for dark matter.</p>

              <p>Later, I joined the <a href="https://sites.google.com/view/cheuk-lab/">Cheuk Lab</a> at Princeton University, where I investigated optical tweezer arrays of ultracold calcium monofluoride (CaF) molecules as a potential platform for quantum simulation or quantum computation. Among <a href="https://scholar.google.com/citations?user=M8uWxXsAAAAJ&hl=en">other contributions</a>, I devised a scheme to perform <a href="https://www.nature.com/articles/s41567-023-02346-3">Raman sideband cooling</a> on these molecules, a technique capable of reaching the quantum ground state of motion. We implemented this scheme succesfully, marking the first demonstration of this technique in neutral molecules (<a href ="https://www.nature.com/articles/s41567-023-02346-3">Nature Physics</a>).</p>

              <p>During this time, I also founded a <a href="https://sjl-instruments.com">company</a> with the goal of expanding access to high-bandwidth test and measurement equipment. I developed a new technique (<a href="https://www.sjl-instruments.com/user-manual.pdf#page=10">CDF sampling</a>) that enables ultrafast time-domain acquisition at a cost 10x below the state-of-the-art. Our first product has brought picosecond-scale measurements within the reach of individuals and small businesses. Several products based on other novel techniques are in development.</p>

              <p>In 2024, I left the PhD program at Princeton, receiving a master’s degree for my contributions. I now develop computational lithography models at Samsung Semiconductor, enabling better control over the sub-wavelength structures found in modern computer chips.</p>

	    <p>Put simply, I like to solve problems and learn as broadly as possible. I’ve worked with everything from video production to machine learning to machining to differential geometry, and if there’s one thing I learned, it’s that learning is never wasted. It’s not about the subject, it’s about the ideas. And ideas are cross-domain.</p>
	    <p>I first put up this website in July 2018 as a learning project, picking up React in the process. I had been programming for over a decade, but mainly for back-end applications, so I decided to give web development a shot. What you’re seeing is the product of one of my many “don’t know that yet, might as well try” journeys.</p>
	    <p>If I don’t know something, I’ll just learn it — and by now, I’ve gotten a lot of experience doing that.</p>

             <h2>Contact & Info</h2>
              <p>For general inquiries and suggestions: <a href='mailto:contact@samuelj.li'>contact@samuelj.li</a></p>
              <p>To report a bug: <a href='mailto:bug.report@samuelj.li'>bug.report@samuelj.li</a></p>
	  </div>
	</div>
      </Page>
    );
  }
}

export default AboutPage;

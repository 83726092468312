import React, {useState, useEffect} from 'react';

import {Helmet} from 'react-helmet';

import {renderMath} from '../Page.js';
import ImageBanner from '../../components/ImageBanner/ImageBanner.js';

import './blog-container.css';
import './blog-post.css';

import database from './posts/database.json';


const MONTH_LIST = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
];

function renderDate(date) {
    if (date === null) {return null;}
    const [year, month, day] = date;
    return <span className='date'>Published {MONTH_LIST[month-1]} {day}, {year}</span>;
}


export default function({match}) {
    const postId = match.params.postId;
    const metadata = database.find(x => x.id === postId);

    const [intro, setIntro] = useState(null);
    const [body, setBody] = useState(null);

    useEffect(() => {
        if (window.location.hash === '#page-top') {
            window.scrollTo(0, 0);
            document.getElementById('page-container').scrollTo(0, 0);
        }
        renderMath();
    });

    if (intro === null) {
        import(`./posts/${postId}/intro.js`).then(
            data => setIntro(data.default)
        ).catch(error => {
            console.error(`Couldn’t load post ${postId}:`, error);
            setIntro('The blog post you requested could not be found.');
            setBody(' ');
        });
    }

    if (body === null) {
        function importBody() {
            import(`./posts/${postId}/body.js`).then(
                data => setBody(data.default)
            ).catch(error => {
                console.error(`Couldn’t load post body ${postId}:`, error);
                setBody(<p className='loading-error'>Content failed to load!</p>);
            });
        }
        
        // Force intro to load first
        setTimeout(importBody, 0);
    }

    const loadingText = <div className='loading-placeholder'>Loading Post...</div>;

    return <>
        <Helmet>
            <title>Samuel J. Li — {metadata.title || 'Blog'}</title>
        </Helmet>
        <div id='page-top'/>
        <ImageBanner image='blog' thin>
            <h1>{metadata.title || 'Loading...'}</h1>
        </ImageBanner>
        <div id='content-container' className='blog'>
            <div id='blog-content'>
                <p className='aux-info'>
                    {renderDate(metadata.date)}
                    ｜
                    <span className='read-time'>{metadata.readTime} minute read</span>
                </p>
                {intro || loadingText}
                {body || (intro ? loadingText : null)}
            </div>
        </div>
    </>;
}

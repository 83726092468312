import React from 'react';

import './banner.css';
import downArrow from './down-arrow.svg';


const resolutions = [
    256,
    512,
    1024,
    2048,
    4096
];

class ImageBanner extends React.PureComponent {
    render() {
        const {image, children, thin} = this.props;

        return (<div className={'banner-container' + (thin ? ' thin' : '')}>
            <img
                className='banner-background'
                src={`/images/banner/${image}-1024.jpg`}
                srcSet={resolutions.map(
                    x => 
                        `/images/banner/${image}-${x}.jpg ${x}w`
                ).join(', ')}
                alt={`${image} banner background`}
            />
            <div className='banner-overlay'>
                <span className='banner-text'>
                    {children}
                </span>
                <a
                    href='#banner-content-anchor'
                    className='down-arrow'
                >
                    <img src={downArrow}/>
                </a>
            </div>
            <div id='banner-content-anchor'/>
        </div>);
    }
}

export default ImageBanner;
